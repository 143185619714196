import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../../layouts/"
import SEO from "../../../components/seo/"
import Crumbs from "../../../components/breadcrumb/"
import BackNav from "../../../components/back-nav/"

import Split from "../../../components/split/"
import TextBlock from "../../../components/text-block/"
import ImageBlock from "../../../components/image-block/"

import { psColour, wlColour, siteColour } from "../../../data/data.colours"

const AstonPage = ({data, pageContext, location}) => {
	
	const image = useStaticQuery(graphql`
		query {
			image01: file(relativePath: { eq: "properties/aston41/aston41-01.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2000) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	
	return (
		<>
			<SEO title="Aston 41"
				path={ location.pathname }/>
			<Layout>
				<Crumbs
					pageContext={pageContext}
					location={location}/>
				<BackNav
					hex={wlColour.hex}
					link={`/w-lamb/current-developments`}/>
				<Split>
					<ImageBlock 
						image={ data.image01.childImageSharp.fluid }/>
					<TextBlock 
						title={`Aston 41 (Phase 3)`}
						titleTag={ `h1` }
						text={`
							<h2>Aston Clinton, Hertfordshire</h2>
							<p>A warehouse development by W Lamb Ltd in partnership with Westhall Estates. Phase 1, 40,000ft<sup>2</sup>, sold to Mercedes Benz Retail Group and Phase 2 sold to Lookers PLC for a new Jaguar Landrover dealership. Phase 3, partly sold, with two remaining units for sale.</p>
							<a href="https://aston41.com/" target="_blank">aston41.com</a>
						`}
						bgColour={ wlColour.slug }/>

				</Split>
			</Layout>
		</>
	)
}

export default AstonPage
